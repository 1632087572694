/******* screen-small.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.roothome {
  margin-top: 105px;
  width: 90%;
  margin-left: 5%;
}
#head {
  margin-top: 40px;
  margin-bottom: 30px;
}
.wrapper__part--fix {
  border-width: 4.6875vw 4.6875vw 0;
}
.cb-layout3 .wrapper__part--fix {
  border-width: 4.6875vw 4.6875vw 0;
}
.wrapper__part--scroll {
  border-width: 0 4.6875vw 4.6875vw;
}
.cb-layout3 .wrapper__part--scroll {
  border-width: 0 4.6875vw 4.6875vw;
}
.section--multimood {
  margin-top: 20px;
  height: 290px;
}
.c2 .section--footer {
  margin-top: 180px;
}
.c2 .footcontent {
  padding-top: 220px;
}
#services {
  width: 100%;
}
#services .meta {
  width: 100%;
  margin: 5px 0;
}
#services .meta:first-child {
  margin-top: 0;
}
#services .meta:last-child {
  margin-bottom: 0;
}
.toplink {
  display: none;
}
.footpart--services {
  margin-bottom: 30px;
}
#root #disp.zoom {
  border: 4.6875vw solid #fff;
}
#root #disp.zoom div.prev a {
  left: 4.6875vw;
  margin-left: 4.6875vw;
}
#root #disp.zoom div.next a {
  right: 4.6875vw;
  margin-right: 4.6875vw;
}
#root #disp.zoom a.quit {
  top: 4.6875vw;
  right: 4.6875vw;
  margin: 5px 5% 0 0 !important;
}
#root #disp.zoom div.foot {
  padding: 5%;
}
#root #disp.zoom div.foot fieldset p {
  font-size: 6.25vw;
}
.batch--root {
  float: left;
  width: 500px;
  height: 500px;
  left: 50%;
  border-width: 16px;
  margin: -80px 0 0 -266px !important;
  transform: rotate(0);
  shape-outside: none;
}
.batch--root .batch__text {
  padding: 0 100px;
}
.batch--root .batch__text--title {
  font-size: 48px;
  margin: 30px 0;
  padding: 0 70px;
}
.root-batch-text {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 5%;
  font-size: 20px;
}
.togglenavigation {
  margin-top: 0;
  top: 20px;
  right: 4.6875vw;
  margin-right: 5%;
}
.ttext {
  display: none;
}
.navigation {
  padding: 0 4.6875vw;
}
body.cb-toggle-target-active .navigation {
  padding: 4.6875vw;
}
.navicontent {
  padding: 4.6875vw;
}
div.sub1 {
  width: 100%;
  margin-top: 100px;
}
div.sub1 > .item {
  margin: 10px 0;
}
div.sub1 > .item > .menu {
  font-size: 28px;
}
div.sub2 > .item > .menu {
  font-size: 20px;
}
#naviservices {
  width: 100%;
  margin-top: 50px;
}
#naviservices .meta {
  margin: 10px 0;
  font-size: 28px;
}
.wrapper {
  font-size: 18px;
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 20px;
}
.south h3 {
  font-size: 28px;
}
h4 {
  font-size: 22px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 5%;
  margin-left: 5%;
}
.area .part {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.area > .slim .part {
  width: 90%;
}
div.side {
  margin-bottom: 30px;
}
div.side div.unit {
  margin-top: 60px;
}
div.farnorth {
  padding-bottom: 5px;
}
div.east {
  margin-left: 0;
  width: 100%;
}
.cb-layout3 div.east {
  margin-left: 0;
  width: 100%;
}
div.south {
  width: 100%;
  margin-left: 0;
}
.unit {
  margin-top: 15px;
  margin-bottom: 15px;
}
div.unit.fold {
  margin-top: 30px;
  margin-bottom: 30px;
}
div.unit.fold + div.unit.fold {
  margin-top: -30px;
}
.normcontent .area div.flat,
.normcontent .area div.edge {
  margin-top: 30px;
  margin-bottom: 30px;
}
.normcontent .area div.flat.wide div.tiny,
.normcontent .area div.edge.wide div.tiny {
  width: 100%;
}
.normcontent .area {
  margin-bottom: 30px;
}
.normcontent .area .unit.flat:last-child,
.normcontent .area .unit.edge:last-child {
  margin-bottom: -30px;
}
.normcontent .area div.edge {
  margin-top: -30px;
}
div.east div.unit,
.cb-layout3 div.east div.unit {
  margin: 15px 0;
  width: 100%;
}
div.east div.unit.slim,
.cb-layout3 div.east div.unit.slim {
  width: 100%;
}
div.east div.unit.slim + .slim,
.cb-layout3 div.east div.unit.slim + .slim {
  margin-top: -15px;
}
div.side div.seam + div.seam {
  margin-top: 15px !important;
}
#view div.side div.seam div.text {
  position: relative;
  background: #CCC7C2;
  padding: 15px 5%;
  color: #7B7067;
}
#view div.side div.seam div.text a {
  color: #7B7067;
}
#view div.side div.seam:hover div.pict img,
#view div.side div.seam:focus div.pict img {
  opacity: 1;
}
div.south div.unit {
  margin: 8px 0;
  width: 100%;
  height: 90.625vw;
}
div.south div.unit.slim {
  width: 100%;
  height: 90.625vw;
}
div.south div.pure div.body,
div.south div.edge div.body {
  padding: 0;
}
div.south div.pure div.part,
div.south div.edge div.part {
  padding-left: 5%;
  padding-right: 5%;
}
div.south div.seam div.body {
  padding: 15px 5%;
}
#view div.south div.seam div.link a.open {
  background-size: 15vw 15vw;
  background-position: right 5% bottom 4.6875vw;
}
div.south div.flat div.body {
  padding: 15px 5%;
}
div.south div.edge div.pict:after {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/*# sourceMappingURL=./screen-small.css.map */